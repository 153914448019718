<template>
    <section id="contact" class="contact-area">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-10">
                    <div class="section-title text-center pb-30">
                        <h3 class="title">{{ $t('ContactUs') }}</h3>
                        <p class="text"> {{ $t('MessageText') }} </p>
                    </div>
                    <!-- section title -->
                </div>
            </div>
            <!-- row -->
            <div class="row">
                <div class="col-lg-12">
                    <div class="contact-map mt-30">
                        <iframe src="https://www.google.com/maps/d/embed?mid=1q3cItN7wNmMdmL7rZR2gGOK4zs8YhjQF" title="JM Electric" width="640" height="480"  frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                        <!-- <iframe id="gmap_canvas" src="https://maps.google.com/maps?q=Mission%20District%2C%20San%20Francisco%2C%20CA%2C%20USA&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe> -->
                    </div>
                    <!-- row -->
                </div>
            </div>
            <!-- row -->
            <div class="contact-info pt-30">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-contact-info contact-color-1 mt-30 d-flex ">
                            <div class="contact-info-icon">
                                <i class="lni lni-map-marker"></i>
                            </div>
                            <div class="contact-info-content media-body">
                                <p class="text"> {{ $t('AddressDescription') }} </p>
                                <p class="text"> {{ $t('BoxNumber') }}</p>
                            </div>
                        </div>
                        <!-- single contact info -->
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-contact-info contact-color-2 mt-30 d-flex ">
                            <div class="contact-info-icon">
                                <i class="lni lni-envelope"></i>
                            </div>
                            <div class="contact-info-content media-body">
                                <p class="text">info@jmelectric.sd</p>
                                <p class="text">www.jmelectric.sd</p>
                            </div>
                        </div>
                        <!-- single contact info -->
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-contact-info contact-color-3 mt-30 d-flex ">
                            <div class="contact-info-icon">
                                <i class="lni lni-phone"></i>
                            </div>
                            <div class="contact-info-content media-body" dir="ltr">
                                <p class="text">+249 183 259 990</p>
                                <p class="text">+249 990 989 999</p>
                            </div>
                        </div>
                        <!-- single contact info -->
                    </div>
                </div>
                <!-- row -->
            </div>
            <!-- contact info -->
            <div class="row">
                <div class="col-lg-12">
                    <div class="contact-wrapper form-style-two pt-115">
                        <h4 class="contact-title pb-10"><i class="lni lni-envelope"></i> {{ $t('LeaveMessage') }}</h4>

                        <!-- <form id="contact-form" > -->
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-input mt-25">
                                        <label>{{ $t('Name') }}</label>
                                        <div class="input-items default">
                                            <input name="name" type="text" v-model="name" :placeholder="$t('Name')">
                                            <i class="lni lni-user"></i>
                                        </div>
                                    </div>
                                    <!-- form input -->
                                </div>
                                <div class="col-md-6">
                                    <div class="form-input mt-25">
                                        <label> {{ $t('Email') }} </label>
                                        <div class="input-items default">
                                            <input type="email" name="email" v-model="email" :placeholder="$t('Email')">
                                            <i class="lni lni-envelope"></i>
                                            <p class="form-message error" v-if="emailValidation"> {{ $t('Validation.EmailRequired') }}</p>
                                        </div>
                                    </div>
                                    <!-- form input -->
                                </div>
                                <div class="col-md-12">
                                    <div class="form-input mt-25">
                                        <label> {{ $t('Subject') }} </label>
                                        <div class="input-items default">
                                            <input type="text" name="subject" v-model="subject"  :placeholder="$t('Subject')">
                                            <i class="lni lni-pencil-alt"></i>
                                        </div>
                                    </div>
                                    <!-- form input -->
                                </div>
                                <div class="col-md-12">
                                    <div class="form-input mt-25">
                                        <label> {{ $t('Message') }} </label>
                                        <div class="input-items default">
                                            <textarea name="message" :placeholder="$t('Message')" v-model="message" ></textarea>
                                            <i class="lni lni-pencil-alt"></i>
                                            <p class="form-message error" v-if="messageValidation">{{ $t('Validation.MessageRequired') }}</p>
                                        </div>
                                    </div>
                                    <!-- form input -->
                                </div>
                                <p class="form-message" v-if="responseMessage"> {{ responseMessage}}</p>
                                <div class="col-md-12">
                                    <div class="form-input light-rounded-buttons mt-30">
                                        <button class="main-btn light-rounded-two" @click="inputValidation" v-if="!loading" > {{ $t('SendMessage') }} </button>
                                        <button class="main-btn light-rounded-two" disabled v-if="loading" > {{ $t('Loading') }} </button>
                                    </div>
                                    <!-- form input -->
                                </div>
                            </div>
                            <!-- row -->
                        <!-- </form> -->
                    </div>
                    <!-- contact wrapper form -->
                </div>
            </div>
            <!-- row -->
        </div>
        <!-- container -->
    </section>
</template>


<script>
export default {
    data() {
        return {
            name:'',
            email: '',
            subject: '',
            message: '',
            loading: false,
            responseMessage: '',
            emailValidation: false,
            messageValidation: false,
            baseUrl: process.env.VUE_APP_EMAIL_URL
        }
    },
    methods: {
        sendEmail(){
            console.log('amro boney');
            
        },
        inputValidation(){
            if (this.email  && this.message) {
                this.SentData('POST', this.baseUrl, this.prepareData())
            }else{
                this.emailValidation = (!this.email)?  true : false;
                this.messageValidation = (!this.message)?  true : false;
            }
        },
        prepareData(){
            return {
                name: this.name,
                email: this.email,
                subject: this.subject,
                message: this.message,
            }
        },
        SentData(method, url, data){
            this.loading = true
            this.axios({
                method: method,
                url: url,
                data: data,
            })
            .then(response => {
                this.responseMessage = this.$t('SendSuccess');
                this.loading = false
                this.validData()
                console.log(response);
                
            })
            .catch(error => {
                this.loading = false;
                this.responseMessage = this.$t('SendFaild');
                this.allerrors = error.response.data.errors
            });
        },
        validData(){
            this.name = this.email = this.subject = this.message = ''
            this.emailValidation = this.messageValidation = false
        }
    },
}
</script>